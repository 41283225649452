import { ThemeProvider } from "emotion-theming"
import CustomTheme from "./src/style/theme"
import React from "react"
import { CSSReset } from "@chakra-ui/core"
import "./src/style/global.css"

export default ({ element }) => (
  <ThemeProvider theme={CustomTheme}>
    <CSSReset />
    {element}
  </ThemeProvider>
)
